/*!

=========================================================
* VojoPay
=========================================================


*/

import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import classnames from "classnames";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function Signup() {
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [messageFocus, setMessageFocus] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [state, handleSubmit] = useForm("mrgwzgkl");

  return (
    <div className="section section-signup">
      <Container>
        <div className="squares square-1" />
        <div className="squares square-2" />

        <div className="squares square-4" />
        <div className="squares square7" />
        <div className="squares square8" />

        <div className="content-center brand">
          <h1 className="h1-seo">
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/images/vojo.png"}
              width={230}
              height={250}
              alt="My Image"
            />
            •VojoPay
          </h1>

          <h3 className="display-3 text-white">
            We are building a next-generation, AI-powered multi-currency remittance platform, utilizing predictive analysis to give users more bang for their buck. <br />
            <br />
          </h3>

         
        </div>

        <Row className="mb-lg-auto">
          <Col lg="6">
            <h3 className="d-sm-block">
              You've hustled for that cash money, slinging it back home to your
              fam in Africa shouldn't be another grind. It's gotta be
              easy-peasy, safe as houses, and straight-up.
              <br />
              <br />
              And fees to send your own dough? Nah, forget about it.
              <br /> <br />
              That's where VojoPay steps in, kickin' all the hassles to the
              curb. We got you!
            </h3>

            <h4 className="display-4 text-white">
            <span className="text-white">
              Interested in learning more? Contact us
            </span>
          </h4>
          <h3 className="d-sm-block">
          <span>
          <div style={{display: 'flex'}}>
          
  <img
    src={process.env.PUBLIC_URL + "/images/icons-canada-96.png"}
    width={30}
    height={30}
    alt="My Image"
    style={{alignSelf: 'flex-start'}} 
  />
  <div style={{marginLeft: '10px'}}>
    Vojo Technologies Limited, Canada 
    <p> (Reg No. 1505816-3)</p>
    <p>1108-250 Consumers Road North York Toronto, ON, Canada M2J 4V6</p>
   
   

  </div>

</div>
</span>
 
            <span>
            <div style={{display: 'flex'}}>
              <img
                src={process.env.PUBLIC_URL + "/images/icons-uk-96.png"}
                width={30}
                height={30}
                alt="My Image"
              />{" "}
              <div style={{marginLeft: '10px'}}>
               Vojo Technologies Limited, UK 
               <p>(Reg No.14598056) </p>
               5 Brayford Square, London, England, E1 0SG 
              </div>
              </div>
            </span>

           
  
       
          </h3>

            <p className="text-white mb-4"></p>
            {/* <div className="btn-wrapper">
              <Button color="primary" to="register-page" tag={Link}>
                Register an as agent
              </Button>
            </div>
            */}
          </Col>
          <Col className="mb-lg-auto" lg="6">
            <Card className="card-register">
              <CardHeader>
                <CardImg
                  alt="..."
                  src={require("assets/img/square-purple-1.png")}
                />
                <CardTitle tag="h5">Hitusup!</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {state.succeeded ? (
                    <p>Thank you for submitting your data!</p>
                  ) : (
                    <>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": fullNameFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Full Name"
                          type="text"
                          onFocus={(e) => setFullNameFocus(true)}
                          onBlur={(e) => setFullNameFocus(false)}
                          name="fullname" // Add the 'name' attribute to each input field
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={(e) => setEmailFocus(true)}
                          onBlur={(e) => setEmailFocus(false)}
                          name="email" // Add the 'name' attribute to each input field
                        />
                      </InputGroup>

                      <InputGroup
                        className={classnames({
                          "input-group-focus": phoneFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Phone"
                          type="text"
                          onFocus={(e) => setPhoneFocus(true)}
                          onBlur={(e) => setPhoneFocus(false)}
                          name="phone"
                          pattern="^[0-9]{8,13}$"
                        />
                      </InputGroup>

                      <InputGroup
                        className={classnames({
                          "input-group-focus": messageFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-pencil" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Message"
                          type="textarea"
                          onFocus={(e) => setMessageFocus(true)}
                          onBlur={(e) => setMessageFocus(false)}
                          name="message"
                        />
                      </InputGroup>

                      <FormGroup check className="text-left"></FormGroup>
                      <br />

                      <Button
                        className="btn-round"
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={state.submitting}
                      >
                        Contact Us
                      </Button>
                    </>
                  )}
                </form>
              </CardBody>

              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <p></p>
        <p className="text-white text-center mb-3" style={{ fontWeight: 'bold' }}>We are registered and regulated by the Financial Transactions and Reports Analysis Centre of Canada. MSB: M23657070.</p>

        <br />
<br />


        <p className="text-white mb-4  text-center">
        Vojo Technologies Limited, Canada.
© 2023 All Rights Reserved
</p>
      </Container>
    </div>
  );
}
